import { ETraitsTypes } from 'Shared/types';
import { geneMock, phenotypeMock, variantMock, collapsingModelMock, phenotypicCategoryMock, phenotypeIdMock, } from 'Common/mocks';
// Gene-level result
const phenotypeAssociationGLRBaseMock = {
    id: phenotypeIdMock,
    gene: geneMock,
    collapsingModel: collapsingModelMock,
    phenotypicCategory: phenotypicCategoryMock,
    pvalue: 0.0001706196132176885,
    pvalueLog: 3.7679710468929013,
    samples: 7591,
};
export const phenotypeBinaryAssociationGLRMock = Object.assign(Object.assign({}, phenotypeAssociationGLRBaseMock), { cases: 329, controls: 268841, caseFrequency: 0.00303951367781155, controlFrequency: 0.000316172012453458, oddsRatio: 9.6397, QVCases: 1, QVControls: 85, oddsRatioLCI: 229411131931.95, oddsRatioUCI: 1139972 });
export const phenotypeContinuousAssociationGLRMock = Object.assign(Object.assign({}, phenotypeAssociationGLRBaseMock), { caseMedian: -1.35393993035186, controlMedian: 0, beta: -1.03823723604198, betaSTD: 0.249981012496739, yesQV: 16, noQV: 59047, LCI: 229411131931.95, UCI: 1139972 });
// Variant-level result
const phenotypeAssociationVLRCommonMock = {
    id: phenotypeIdMock,
    gene: geneMock,
    collapsingModel: collapsingModelMock,
    phenotypicCategory: phenotypicCategoryMock,
    pvalue: 0.0001706196132176885,
    pvalueLog: 3.7679710468929013,
    phenotype: phenotypeMock,
    variant: variantMock,
};
export const phenotypeBinaryAssociationVLRMock = Object.assign(Object.assign({}, phenotypeAssociationVLRCommonMock), { traitsType: ETraitsTypes.Binary, cases: 12300, AACases: 100, ABCases: 200, BBCases: 300, caseAAF: undefined, caseMAF: 23.333, ABOrBBCasesPercentage: 11.12345, BBCasesPercentage: 5.54545, controls: 20000, AAControls: 1000, ABControls: 2000, BBControls: 3000, controlMaf: 33.456, controlAaf: undefined, ABOrBBControlsPercentage: 20.2222, BBControlsPercentage: 11.1111, oddsRatio: 0.1231, oddsRatioLCI: 23.23232, oddsRatioUCI: 45.454545, transcriptId: 'ENST00000305877', CDNAChange: 'c.34A>T', AAChange: 'p.Lys12*', exonRank: '1/23', consequenceType: 'stop_gained' });
export const phenotypeContinuousAssociationVLRMock = Object.assign(Object.assign({}, phenotypeAssociationVLRCommonMock), { traitsType: ETraitsTypes.Continuous, noSamples: 58457, noAAGenotypes: 56495, noABGenotypes: 1947, noBBGenotypes: 15, MAF: 0.0169098653711275, ABOrBBGenotypes: 0.0335631318747113, BBGenotypes: 0.000256598867543665, effectSize: -0.097098852, effectSizeStandardError: 0.02295762, effectSizeLCI: -0.142095891, effectSizeUCI: -0.052101813, transcriptId: 'ENST00000305877', CDNAChange: 'c.34A>T', AAChange: 'p.Lys12*', exonRank: '1/23', consequenceType: 'stop_gained' });
export const phenotypeContinuousAssociationVLRFinnGenMock = Object.assign(Object.assign({}, phenotypeContinuousAssociationVLRMock), { AAControls: 1000, ABControls: 2000, BBControls: 3000 });
// Phenotype-level result
export const phenotypeAssociationPLRMock = {
    id: phenotypeIdMock,
    category: phenotypicCategoryMock,
    pvalue: 0.0001706196132176885,
    pvalueLog: 3.7679710468929013,
    phenotype: phenotypeMock,
    noSamples: 58457,
    cases: 12300,
    controls: 20000,
    effectSize: -0.097098852,
    effectSizeStandardError: 0.02295762,
    effectSizeLCI: -0.142095891,
    effectSizeUCI: -0.052101813,
};
